import axios from "axios";
import React from "react";
import Sidebar from "../../components/Sidebar";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import SimpleReactValidator from "simple-react-validator";
import { Editor } from "@tinymce/tinymce-react";

class AddBlog1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      category: "",
      authorName: "",
      authorLink: "",
      description: "",
      image: "",
      urlName: "",
      dateOfArticle: "",
      theme: "snow",
      mobile_message: "",
      validError: false,
      showAlert: false,

      date: Date.now(),
    };
    this.handleChange = this.handleChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validator = new SimpleReactValidator({
      className: "text-danger",
      validators: {
        passwordvalid: {
          message:
            "The :attribute must be at least 6 and at most 30 with 1 numeric,1 special charac" +
            "ter and 1 alphabet.",
          rule: function(val, params, validator) {
            return (
              validator.helpers.testRegex(
                val,
                /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z]).{6,30}$/i
              ) && params.indexOf(val) === -1
            );
          },
        },
        passwordMismatch: {
          message: "confirm password must match with password field.",
          rule: function(val, params, validator) {
            return document.getElementById("password_input").value === val
              ? true
              : false;
          },
        },
        whitespace: {
          message: "The :attribute not allowed first whitespace   characters.",
          rule: function(val, params, validator) {
            return (
              validator.helpers.testRegex(val, /[^\s\\]/) &&
              params.indexOf(val) === -1
            );
          },
        },
        specialChar: {
          message: "The :attribute not allowed special   characters.",
          rule: function(val, params, validator) {
            return (
              validator.helpers.testRegex(val, /^[ A-Za-z0-9_@./#&+-]*$/i) &&
              params.indexOf(val) === -1
            );
          },
        },
        specialCharText: {
          message: "The :attribute may only contain letters, dot and spaces.",
          rule: function(val, params, validator) {
            return (
              validator.helpers.testRegex(val, /^[ A-Za-z_@./#&+-]*$/i) &&
              params.indexOf(val) === -1
            );
          },
        },

        zip: {
          message: "Invalid Pin Code",
          rule: function(val, params, validator) {
            return (
              validator.helpers.testRegex(val, /^(\d{5}(\d{4})?)?$/i) &&
              params.indexOf(val) === -1
            );
          },
        },
        website: {
          message: "The Url should be example.com ",
          rule: function(val, params, validator) {
            return (
              validator.helpers.testRegex(
                val,
                /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
              ) && params.indexOf(val) === -1
            );
          },
        },
        Fax: {
          message: "Invalid fax number ",
          rule: function(val, params, validator) {
            return (
              validator.helpers.testRegex(
                val,
                /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/i
              ) && params.indexOf(val) === -1
            );
          },
        },
      },
    });
  }

  componentDidMount() {
    axios
      .get(`${process.env.REACT_APP_PROD_HOST}/blog/blogcategorys`)
      .then((res) => {
        const blogcategories = res.data;
        console.log(blogcategories);
        this.setState({ blogcategories });
      });
  }
  handleChange(value) {
    this.setState({ description: value });
  }

  onChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleThemeChange(newTheme) {
    if (newTheme === "core") newTheme = null;
    this.setState({ theme: newTheme });
  }

  onFileChange(e) {
    this.setState({ image: e.target.files[0] });
  }
  //   handleSubmit(event) {
  //     event.preventDefault();
  //     if (this.validator.allValid()) {
  //       const post = {
  //         title: this.state.title,
  //         category: this.state.category,
  //         description: this.state.description,
  //       };

  //       console.log(post);
  //       axios
  //         .post(`${process.env.REACT_APP_PROD_HOST}/blog/AddBlog1`, post)
  //         .then((res) => {
  //           console.log(res);
  //           console.log(res.data);
  //         });

  //       this.props.history.push("/article");
  //     } else {
  //       this.validator.showMessages();
  //       this.forceUpdate();
  //     }
  //   }

  componentDidUpdate() {
    if (this.state.showAlert) {
      setTimeout(() => {
        this.setState({
          showAlert: false,
        });
      }, 2000);
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.validator.allValid()) {
      const formdata = new FormData();
      formdata.append("title", this.state.title);
      formdata.append("category", this.state.category);
      formdata.append("description", this.state.description);
      formdata.append("file", this.state.image);
      formdata.append("date", this.state.dateOfArticle);
      formdata.append("authorLink", this.state.authorLink);
      formdata.append("authorName", this.state.authorName);
      formdata.append("urlName", this.state.urlName);
      axios
        .post(process.env.REACT_APP_PROD_HOST + "/blog/AddBlog1", formdata)
        .then((response) => {
          console.log(response);
          this.props.history.push("/article");
        })
        .catch((error) => {
          // handle error
          console.log(error);
          console.log(error.response);
          this.setState({
            showAlert: true,
            popupMsg: error.response.data.message,
          });
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  render() {
    console.log(this.state);
    return (
      <div>
        <Sidebar></Sidebar>
        <div className="admin-wrapper col-12">
          <div className="admin-content">
            <div className="admin-head">Article - Add New</div>
            <div className="admin-data">
              <div className="container-fluid p-0">
                {this.state.showAlert ? (
                  <div class="alert">
                    <strong>{this.state.popupMsg}</strong>
                  </div>
                ) : (
                  ""
                )}
                <form
                  className="form-contact contact_form"
                  onSubmit={this.handleSubmit}
                >
                  <div className="row m-0">
                    <div className="col-lg-12 p-0"></div>
                    <div className="col-lg-12 p-0">
                      <div className="form-group tags-field row m-0">
                        <label className="col-lg-2 p-0">Title</label>
                        <input
                          className="form-control col-lg-10"
                          name="title"
                          onChange={this.onChange}
                          value={this.state.title}
                          type="text"
                          onfocus="this.placeholder = 'Menu Name'"
                          onblur="this.placeholder = ''"
                          placeholder=""
                        />
                        {this.validator.message(
                          "Title",
                          this.state.title,
                          "required|whitespace|min:1|max:150"
                        )}
                        {this.state.mobile_message}
                      </div>
                      <div className="form-group tags-field row m-0">
                        <label className="col-lg-2 p-0">Date of Article</label>
                        <input
                          className="form-control col-lg-10"
                          name="dateOfArticle"
                          onChange={this.onChange}
                          value={this.state.dateOfArticle}
                          type="datetime-local"
                          onfocus="this.placeholder = 'Menu Name'"
                          onblur="this.placeholder = ''"
                          placeholder=""
                        />
                        {this.validator.message(
                          "Title",
                          this.state.title,
                          "required|whitespace|min:1|max:150"
                        )}
                        {this.state.mobile_message}
                      </div>

                      <div className="form-group tags-field row m-0">
                        <label className="col-lg-2 p-0">Author Name</label>
                        <input
                          className="form-control col-lg-10"
                          name="authorName"
                          onChange={this.onChange}
                          value={this.state.authorName}
                          type="text"
                          onfocus="this.placeholder = 'Menu Name'"
                          onblur="this.placeholder = ''"
                          placeholder=""
                        />
                        {this.validator.message(
                          "Title",
                          this.state.authorName,
                          "required|whitespace|min:1|max:150"
                        )}
                        {this.state.mobile_message}
                      </div>
                      <div className="form-group tags-field row m-0">
                        <label className="col-lg-2 p-0">Author Link</label>
                        <input
                          className="form-control col-lg-10"
                          name="authorLink"
                          onChange={this.onChange}
                          value={this.state.authorLink}
                          type="text"
                          onfocus="this.placeholder = 'Menu Name'"
                          onblur="this.placeholder = ''"
                          placeholder=""
                        />
                        {this.validator.message(
                          "Title",
                          this.state.authorLink,
                          "required|whitespace|min:1|max:150"
                        )}
                        {this.state.mobile_message}
                      </div>

                      <div className="form-group tags-field row m-0 ">
                        <label className="col-lg-2 p-0">Category Name</label>

                        <select
                          className="form-control col-lg-10"
                          name="category"
                          onChange={this.onChange}
                        >
                          <option>Select Category</option>
                          {this.state.blogcategories &&
                            this.state.blogcategories.map((data, index) => {
                              return (
                                <option value={data.category} key={index}>
                                  {data.category}
                                </option>
                              );
                            })}
                        </select>

                        {this.validator.message(
                          "category Name",
                          this.state.category,
                          "required"
                        )}
                      </div>

                      <div className="form-group tags-field row m-0">
                        <label className="col-lg-2 p-0">Upload Image</label>
                        <input
                          type="file"
                          onChange={this.onFileChange}
                          name="file"
                          className="form-control col-lg-10"
                        />

                        {this.validator.message(
                          "Image",
                          this.state.image,
                          "required"
                        )}
                      </div>

                      <div className="form-group tags-field row m-0">
                        <label className="col-lg-2 p-0">Description</label>

                        {/* <ReactQuill
                          className=" col-lg-10 height"
                          theme={this.state.theme}
                          onChange={this.handleChange}
                          value={this.state.description}
                          modules={AddBlog1.modules}
                          formats={AddBlog1.formats}
                          bounds={".app"}
                          placeholder={this.props.placeholder}
                        /> */}
                        <Editor
                          className=" col-lg-10 height"
                          style={{ width: "990px" }}
                          // initialValue=""
                          apiKey="0ssde251g70hc0lt4vgz4n91fot0yd7yhn5btlevh708ypcl"
                          init={{
                            height: 300,
                            file_picker_callback: function(
                              callback,
                              value,
                              meta
                            ) {
                              // Provide file and text for the link dialog
                              var input = document.createElement("input");
                              input.setAttribute("type", "file");
                              input.setAttribute("accept", "image/*");
                              input.onchange = function() {
                                var file = this.files[0];

                                var reader = new FileReader();
                                reader.onload = function() {
                                  var id = "blobid" + new Date().getTime();
                                  var blobCache =
                                    window.tinymce.activeEditor.editorUpload
                                      .blobCache;
                                  var base64 = reader.result.split(",")[1];
                                  var blobInfo = blobCache.create(
                                    id,
                                    file,
                                    base64
                                  );
                                  blobCache.add(blobInfo);
                                  callback(blobInfo.blobUri(), {
                                    title: file.name,
                                  });
                                };
                                reader.readAsDataURL(file);
                              };

                              input.click();
                            },
                            image_title: true,
                            width: 993,
                            menubar: true,
                            paste_data_images: true,
                            automatic_uploads: true,
                            file_picker_types: "file image media",
                            plugins: [
                              "advlist autolink lists link image",
                              "charmap print preview anchor help",
                              "searchreplace visualblocks code",
                              "insertdatetime media table paste wordcount",
                            ],
                            toolbar:
                              "code undo redo | image | formatselect | bold italic | \
                                 alignleft aligncenter alignright | \
                                 bullist numlist outdent indent | help",
                          }}
                          onEditorChange={this.handleChange}
                        />

                        {this.validator.message(
                          "Description",
                          this.state.description,
                          "required"
                        )}
                      </div>
                      <div className="form-group tags-field row m-0 urlNameContainer">
                        <label className="col-lg-2 p-0">Url Name</label>
                        <input
                          className="form-control col-lg-10"
                          name="urlName"
                          onChange={this.onChange}
                          value={this.state.urlName}
                          type="text"
                          onfocus="this.placeholder = 'Menu Name'"
                          onblur="this.placeholder = ''"
                          placeholder=""
                        />
                        {this.validator.message(
                          "Url Name",
                          this.state.urlName,
                          "required|whitespace|min:1|max:300"
                        )}
                        {this.state.mobile_message}
                      </div>
                    </div>

                    <div className="col-lg-12 p-0">
                      <div className="form-group tags-field  row m-0">
                        <label className="col-lg-2 p-0" />
                        <div className="col-lg-6 p-0">
                          <button
                            className="button button-contactForm boxed-btn margin"
                            type="submit"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
AddBlog1.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false,
  },
};

AddBlog1.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

AddBlog1.propTypes = {
  placeholder: PropTypes.string,
};
export default AddBlog1;
