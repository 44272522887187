import axios from "axios";
import React from "react";
import Sidebar from "../../components/Sidebar";
import SimpleReactValidator from "simple-react-validator";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Loader from "react-loader-spinner";
import { Editor } from "@tinymce/tinymce-react";

class EditPrivatePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      description: "",
      urlName: "",
      PrivatePages: [],
      theme: "snow",
      mobile_message: "",
      showAlert: false,
      popupMsg: "",
      validError: false,
      loading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.onChange = this.onChange.bind(this);

    this.validator = new SimpleReactValidator({
      className: "text-danger",
      validators: {
        passwordvalid: {
          message:
            "The :attribute must be at least 6 and at most 30 with 1 numeric,1 special charac" +
            "ter and 1 alphabet.",
          rule: function(val, params, validator) {
            return (
              validator.helpers.testRegex(
                val,
                /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z]).{6,30}$/i
              ) && params.indexOf(val) === -1
            );
          },
        },
        passwordMismatch: {
          message: "confirm password must match with password field.",
          rule: function(val, params, validator) {
            return document.getElementById("password_input").value === val
              ? true
              : false;
          },
        },
        whitespace: {
          message: "The :attribute not allowed first whitespace   characters.",
          rule: function(val, params, validator) {
            return (
              validator.helpers.testRegex(val, /[^\s\\]/) &&
              params.indexOf(val) === -1
            );
          },
        },
        specialChar: {
          message: "The :attribute not allowed special   characters.",
          rule: function(val, params, validator) {
            return (
              validator.helpers.testRegex(val, /^[ A-Za-z0-9_@./#&+-]*$/i) &&
              params.indexOf(val) === -1
            );
          },
        },
        specialCharText: {
          message: "The :attribute may only contain letters, dot and spaces.",
          rule: function(val, params, validator) {
            return (
              validator.helpers.testRegex(val, /^[ A-Za-z_@./#&+-]*$/i) &&
              params.indexOf(val) === -1
            );
          },
        },

        zip: {
          message: "Invalid Pin Code",
          rule: function(val, params, validator) {
            return (
              validator.helpers.testRegex(val, /^(\d{5}(\d{4})?)?$/i) &&
              params.indexOf(val) === -1
            );
          },
        },
        website: {
          message: "The Url should be example.com ",
          rule: function(val, params, validator) {
            return (
              validator.helpers.testRegex(
                val,
                /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
              ) && params.indexOf(val) === -1
            );
          },
        },
        Fax: {
          message: "Invalid fax number ",
          rule: function(val, params, validator) {
            return (
              validator.helpers.testRegex(
                val,
                /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/i
              ) && params.indexOf(val) === -1
            );
          },
        },
      },
    });
  }
  componentDidMount() {
    const { _id } = this.props.match.params;
    axios
      .get(`${process.env.REACT_APP_PROD_HOST}/private/privatepages`)
      .then((res) => {
        const PrivatePages = res.data.map((item) => _id !== item._id && item);
        console.log(PrivatePages);

        this.setState({ PrivatePages, loading: true });
      });
    this.unsubscribe = axios
      .get(`${process.env.REACT_APP_PROD_HOST}/private/privatepages`)
      .then((res) => {
        const PrivatePages = res.data.map(
          (item) => _id !== item.urlName && item
        );
        console.log(PrivatePages);
        this.setState({ PrivatePages, loading: true });
      });
    console.log(_id);
    axios
      .get(
        `${process.env.REACT_APP_PROD_HOST}/private/update_privatepage/${_id}`
      )
      .then((res) => {
        console.log(res.data);
        const private1 = {
          title: res.data[0].title,
          description: res.data[0].description,
          urlName: res.data[0].urlName,
        };
        console.log(private1.title);
        this.setState({
          title: private1.title,
          description: private1.description,
          urlName: private1.urlName,
          loading: true,
        });
      });
  }

  handleChange(html) {
    this.setState({ description: html });
  }
  onChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleThemeChange(newTheme) {
    if (newTheme === "core") newTheme = null;
    this.setState({ theme: newTheme });
  }

  componentDidUpdate() {
    if (this.state.showAlert) {
      setTimeout(() => {
        this.setState({
          showAlert: false,
        });
      }, 2000);
    }
  }

  handleSubmit(e) {
    const { _id } = this.props.match.params;
    e.preventDefault();
    if (this.validator.allValid()) {
      const post = {
        title: this.state.title,
        description: this.state.description,
        urlName: this.state.urlName,
      };
      let bool = this.state.PrivatePages.some(
        (item) => post.urlName === item.urlName
      );
      if (!bool) {
        axios
          .put(
            `${process.env.REACT_APP_PROD_HOST}/private/update_privatepage_patch/${_id}`,
            post
          )
          .then((res) => {
            console.log(res.data);
            this.props.history.push("/privatepage");
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.setState({
          showAlert: true,
          popupMsg: "Url Name is already exist in other Privatepage!",
        });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  render() {
    return (
      <div>
        <Sidebar></Sidebar>
        <div className="admin-wrapper col-12">
          <div className="admin-content">
            <div className="admin-head">Edit Private Page</div>
            {this.state.loading ? (
              <div className="admin-data">
                <div className="col-lg-12 p-0 text-right mb-30">
                  <a href="/privatepage">
                    <button className="button button-contactForm boxed-btn">
                      Back
                    </button>
                  </a>
                </div>
                <div className="container-fluid p-0">
                  {this.state.showAlert ? (
                    <div class="alert">
                      <strong>{this.state.popupMsg}</strong>
                    </div>
                  ) : (
                    ""
                  )}
                  <form
                    className="form-contact contact_form"
                    onSubmit={this.handleSubmit}
                  >
                    <div className="row m-0">
                      <div className="col-lg-12 p-0"></div>
                      <div className="col-lg-12 p-0">
                        <div className="form-group tags-field row m-0">
                          <label className="col-lg-2 p-0">Title</label>
                          <input
                            className="form-control col-lg-10"
                            name="title"
                            onChange={this.onChange}
                            value={this.state.title}
                            type="text"
                            onfocus="this.placeholder = 'Menu Name'"
                            onblur="this.placeholder = ''"
                            placeholder=""
                          />
                          {this.validator.message(
                            "Title",
                            this.state.title,
                            "required|whitespace|min:1|max:300"
                          )}
                          {this.state.mobile_message}
                        </div>

                        <div className="form-group tags-field row m-0">
                          <label className="col-lg-2 p-0">Description</label>
                          {/* <textarea
                          className="form-control col-lg-10"
                          name="description"
                          onChange={this.onChange}
                          value={this.state.description}
                          type="text"
                          onfocus="this.placeholder = 'Menu Name'"
                          onblur="this.placeholder = ''"
                          placeholder=""
                        /> */}
                          <Editor
                            className=" col-lg-10 height"
                            style={{ width: "990px" }}
                            value={this.state.description}
                            apiKey="0ssde251g70hc0lt4vgz4n91fot0yd7yhn5btlevh708ypcl"
                            init={{
                              height: 300,
                              width: 993,
                              menubar: true,
                              file_picker_callback: function(
                                callback,
                                value,
                                meta
                              ) {
                                var input = document.createElement("input");
                                input.setAttribute("type", "file");
                                input.setAttribute("accept", "image/*");
                                input.onchange = function() {
                                  var file = this.files[0];
  
                                  var reader = new FileReader();
                                  reader.onload = function() {
                                    var id = "image-" + new Date().getTime();
                                    var blobCache =
                                      window.tinymce.activeEditor.editorUpload
                                        .blobCache;
                                    var base64 = reader.result.split(",")[1];
                                    var blobInfo = blobCache.create(
                                      id,
                                      file,
                                      base64
                                    );
                                    blobCache.add(blobInfo);
                                    callback(blobInfo.blobUri(), {
                                      title: file.name,
                                    });
                                  };
                                  reader.readAsDataURL(file);
                                };
  
                                input.click();
                              },
                              paste_data_images: true,
                              plugins: [
                                "advlist autolink lists link image",
                                "charmap print preview anchor help",
                                "searchreplace visualblocks code",
                                "insertdatetime media table paste wordcount",
                              ],
                              toolbar:
                                "code undo redo | image | formatselect | bold italic | \
                                 alignleft aligncenter alignright | \
                                 bullist numlist outdent indent | help",
                            }}
                            onEditorChange={this.handleChange}
                          />

                          {/* <ReactQuill
                            className=" col-lg-10 height"
                            theme={this.state.theme}
                            onChange={this.handleChange}
                            value={this.state.description}
                            modules={EditPrivatePage.modules}
                            formats={EditPrivatePage.formats}
                            bounds={".app"}
                            placeholder={this.props.placeholder}
                          /> */}

                          {this.validator.message(
                            "Description",
                            this.state.description,
                            "required"
                          )}
                        </div>
                        <div className="form-group tags-field row m-0 urlNameContainer">
                          <label className="col-lg-2 p-0">Url Name</label>
                          <input
                            className="form-control col-lg-10"
                            name="urlName"
                            onChange={this.onChange}
                            value={this.state.urlName}
                            type="text"
                            onfocus="this.placeholder = 'Menu Name'"
                            onblur="this.placeholder = ''"
                            placeholder=""
                          />
                          {this.validator.message(
                            "Url Name",
                            this.state.urlName,
                            "required|whitespace|min:1|max:300"
                          )}
                          {this.state.mobile_message}
                        </div>
                      </div>

                      <br />
                      <hr />

                      <div className="col-lg-12 p-0">
                        <div className="form-group tags-field  row m-0">
                          <label className="col-lg-2 p-0" />
                          <div className="col-lg-6 p-0">
                            <button
                              className="button button-contactForm boxed-btn margin"
                              type="submit"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            ) : (
              <div style={{ marginLeft: "500px", marginTop: "200px" }}>
                {" "}
                <Loader
                  type="Circles"
                  color="#0029ff"
                  height={100}
                  width={100}
                  timeout={3000} //3 secs
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
EditPrivatePage.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false,
  },
};

EditPrivatePage.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

EditPrivatePage.propTypes = {
  placeholder: PropTypes.string,
};
export default EditPrivatePage;
