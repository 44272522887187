import Routing from "./routing";
import "react-notifications/lib/notifications.css";
import NotificationContainer from "react-notifications/lib/NotificationContainer";
function App() {
  return (
    <>
      <NotificationContainer />
      <Routing />
    </>
  );
}

export default App;
